<template>
  <div class="main">
    <div class="navbarItems">
      <div class="navbarHeading">
        <span class="navbarHeading">MR Dashboard</span>
      </div>
      <span class="navbarTime"
        ><Date class="no">{{ currentTime }}</Date></span
      >
    </div>
    <header class="app-header">
      <div class="logo-container">
        <img src="@/assets/tat_nhm.jpeg" alt="Logo" class="img1" />
        <img src="@/assets/mr_tat_logo.jpeg" alt="Logo" class="img2" />
      </div>
      <div class="center-image">
        <img src="@/assets/tat_logo-2.jpeg" alt="Logo" class="img3" />
        <span style="color: #337ab7; font-weight: 800; font-size: 16px"
          >FREE DIAGNOSTICS SERVICES (MR SCAN) IN PPP MODE</span
        >
        <span style="color: #337ab7; font-weight: 800; font-size: 16px"
          >DEPARTMENT OF HEALTH AND FAMILY WELFARE, GOVERNMENT OF ODISHA</span
        >
      </div>
      <img src="@/assets/add_annex_logo.jpeg" alt="Logo" class="img4" />
    </header>

    <div class="button-container">
      <button class="ExportExcel" @click="exportToExcel">
        Export to Excel
      </button>
      <a
        target="_blank"
        href="https://addannex.aikenist.com"
        class="LoginRouting"
      >
        <button>Login</button>
      </a>
    </div>
    <div class="data-display-section">
      <div class="status-card">
        <div class="card-content">
          <h2>{{ totalScans }}</h2>
          <p>REGISTERED PATIENTS</p>
        </div>
      </div>

      <div class="status-card">
        <div class="card-content">
          <h2>{{ totalScans }}</h2>
          <p>TOTAL SCANS</p>
        </div>
      </div>
      <div class="status-card">
        <div class="card-content">
          <h2>{{ totalreportedPatients }}</h2>
          <p>REPORTED PATIENTS</p>
        </div>
      </div>
      <div class="status-card">
        <div class="card-content">
          <h2>{{ totalPendingPatients }}</h2>
          <p>REPORTS IN QUEUE</p>
        </div>
      </div>
    </div>
    <div class="main-content">
      <div class="left-side">
        <table>
          <thead>
            <tr>
              <th>District</th>
              <th>InstName</th>
              <th>No of Scan Conducted</th>
              <th>No of Scan Reported</th>
              <th>Report Within TAT</th>
              <th>Report Beyond TAT</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in stats" :key="index">
              <td>{{ item.group2_name }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.total_scans }}</td>
              <td>{{ item.total_scan_reported }}</td>
              <td>{{ item.scan_report_within_tat }}</td>
              <td>{{ item.scan_report_beyond_tat }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="right-side">
        <div class="chart-container">
          <div class="pie-chart">
            <canvas id="pieChart"></canvas>
          </div>
          <div class="chart-labels">
            <ul id="chartLabels"></ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import radConfig from "../config/config";
import { Chart, registerables } from "chart.js";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
Chart.register(...registerables);
export default {
  data() {
    return {
      currentTime: "",
      stats: [],
      totalRegisteredPatients: 0,
      totalScans: 0,
      totalreportedPatients: 0,
      totalPendingPatients: 0,
    };
  },
  methods: {
    exportToExcel() {
      if (this.stats.length === 0) {
        alert("No data to export");
        return;
      }
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("TAT Stats");

      const headerMapping = [
        { header: "District", key: "group2_name", width: 40 },
        { header: "InstName", key: "name", width: 50 },
        { header: "No of Scan Conducted", key: "total_scans", width: 30 },
        {
          header: "No of Scan Reported",
          key: "total_scan_reported",
          width: 30,
        },
        {
          header: "Report Within TAT",
          key: "scan_report_within_tat",
          width: 30,
        },
        {
          header: "Report Beyond TAT",
          key: "scan_report_beyond_tat",
          width: 30,
        },
      ];

      worksheet.columns = headerMapping;

      this.stats.forEach((item) => {
        const row = {};
        headerMapping.forEach((col) => {
          row[col.key] = item[col.key] || "";
        });
        worksheet.addRow(row);
      });

      worksheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true, color: { argb: "FFFFFF" } };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "4F81BD" },
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });

      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber !== 1) {
          row.eachCell((cell) => {
            cell.font = { color: { argb: "000000" } };
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "d7e6da" },
            };
            cell.alignment = { vertical: "middle", horizontal: "center" };
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          });
        }
      });
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "tat_stats.xlsx");
      });
    },

    updateTime() {
      const now = new Date();
      const dateOptions = { day: "2-digit", month: "short", year: "numeric" };
      const formattedDate = now.toLocaleDateString("en-US", dateOptions);
      const timeOptions = {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      };
      const formattedTime = now.toLocaleTimeString("en-US", timeOptions);
      this.currentTime = `${formattedDate} ${formattedTime}`;
    },
    async fetchData() {
      const url = `${radConfig.baseUrl}/stats/get-tat-info`;
      const payload = {
        modality: "MR",
      };
      try {
        const response = await Axios.post(url, payload);
        this.stats = response.data.stats;
        this.totalScans = this.stats.reduce(
          (sum, item) => sum + item.total_scans,
          0
        );
        this.totalreportedPatients = this.stats.reduce(
          (sum, item) => sum + item.total_scan_reported,
          0
        );
        this.totalPendingPatients =
          this.totalScans - this.totalreportedPatients;
        this.initPieChart();

        console.log("Total scans:", this.totalreportedPatients);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    generateRandomColor() {
      const randomColor = `#${Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, "0")}`;
      return randomColor;
    },

    initPieChart() {
      const ctx = document.getElementById("pieChart").getContext("2d");
      const backgroundColors = this.stats.map(() => this.generateRandomColor());
      const hoverBackgroundColors = this.stats.map(() =>
        this.generateRandomColor()
      );

      const data = {
        labels: this.stats.map(
          (item) => `${item.name}: ${item.total_scans}(${item.scan_pct}%)`
        ),
        datasets: [
          {
            data: this.stats.map((item) => item.scan_pct),
            backgroundColor: backgroundColors,
            hoverBackgroundColor: hoverBackgroundColors,
          },
        ],
      };

      new Chart(ctx, {
        type: "pie",
        data: data,
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  return `${context.label}`;
                },
              },
            },
          },
        },
      });

      const labelsContainer = document.getElementById("chartLabels");
      labelsContainer.innerHTML = "";
      this.stats.forEach((item, index) => {
        const labelElement = document.createElement("li");
        labelElement.innerHTML = `
        <span style="background-color: ${backgroundColors[index]}; width: 12px; height: 12px; display: inline-block; border-radius: 50%; margin-right: 8px;"></span>
        ${item.name}: ${item.total_scans} (${item.scan_pct}%)
      `;
        labelsContainer.appendChild(labelElement);
      });
    },
  },
  mounted() {
    this.fetchData();
    this.updateTime();
    setInterval(this.updateTime, 1000);
  },
};
</script>
<style scoped>
body {
  padding: 0px;
  margin: 0px;
}
.navbarItems {
  color: white;
  background-color: #337ab7;
  text-align: center;
  justify-content: space-between;
  display: flex;
}
.button-container {
  display: flex;
  justify-content: flex-end;
}
.LoginRouting button {
  color: white;
  background-color: #337ab7;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 5px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}
.ExportExcel {
  color: white;
  background-color: #5cb85c;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  margin: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}
.navbarHeading {
  font-size: 18px;
  font-weight: 700;
  flex: 1;
}
.navbarTime {
  font-size: 18px;
  font-weight: 700;
}
.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 10px;
}
.logo-container {
  display: flex; 
  gap: 10px;
}
.img1 {
  height: 93px;
}
.img2 {
  height: 93px;
}
.center-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.img3 {
  height: 93px;
  margin: 0 10px;
}
.img4 {
  height: 93px;
  margin-left: 20px;
}
.main-content {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.left-side {
  flex: 1;
  max-width: 50%;
  overflow-y: auto;
  max-height: 400px;
  border: 1px solid #c0c0c0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.right-side {
  flex: 1;
}

table {
  width: 100%;
  border-collapse: collapse;
  max-height: 400px;
}

td,
th {
  border: 1px solid #c0c0c0;
  text-align: left;
  padding: 8px;
}

th {
  background-color: rgb(98, 147, 188);
  color: white;
  font-family: arial, sans-serif;
}
tbody tr:nth-child(even) {
  background-color: #d7e6da;
}

tbody tr:nth-child(odd) {
  background-color: rgb(243, 242, 255);
}

tbody tr:hover {
  background-color: #e0f7fa;
}
.data-display-section {
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.status-card {
  padding: 10px;
  color: #000;
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 250px;
  border: 2px solid black;
}
.status-card .card-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.status-card h2 {
  font-size: 28px;
  margin: 0;
}

.status-card p {
  font-size: 15px;
}

.right-side {
  flex: 1;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: auto;
}
.right-side canvas {
  display: block;
  max-width: 100%;
  max-height: 400px;
}
.dropdown {
  margin-left: 10px;
  padding: 5px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #c0c0c0;
}
.right-side {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
}

.chart-container {
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: 100%;
}

.pie-chart {
  flex: 1;
}

.chart-labels {
  flex: 1;
  overflow-y: auto;
  max-height: 400px;
  margin-right: 30px;
}

.chart-labels ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.chart-labels li {
  margin-bottom: 14px;
  font-size: 13px;
  font-weight: 500;
  color: #333;
  display: flex;
  align-items: center;
}

.chart-labels li span {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 10px;
  border-radius: 50%;
}
</style>
